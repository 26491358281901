import axios from 'axios'
import { Message } from 'element-ui'
import router from "@/router";




/**************后台地址**************************************/
const Api = 'https://zkxxxt.zikaoj.com/api/v1' //
// const Api = 'http://127.0.0.1:8000/api/v1'

/***********************************************************/

const service = axios.create({
    baseURL: Api,
    timeout: 50000
})

/*
 * 设置请求拦截器
 */
service.interceptors.request.use(
        config => {
            config.headers.Authorization = localStorage.getItem('my_token')

            return config
        },
        error => {
            console.log(error)
            return Promise.reject()
        }
    )
    /*
     * 响应拦截器
     */
service.interceptors.response.use(
    response => {
        const res = response.data
        if (response.status === 200) {

            if (res.code === 10004) {
                Message({
                    message: 'token失效,请重新登录!',
                    type: 'error',
                    duration: 2000
                });
                setTimeout(function() {
                    router.push('/login');
                }, 2000);
            }
            return res
        } else {
            Promise.reject()
        }
    },
    error => {
        console.log(error)
        return Promise.reject()
    }
)

export default service
