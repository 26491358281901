 <template>
  <!--
    页面名称：我的题库-作答
    开发人：黄wj
  -->
  <div class="answer">
    <div class="answer-bg">
      <div class="answer-bg-model">
        <div class="answer-bg-model-n">
          <div class="answer-bg-model-n-name1">{{ name }}</div>

          <div class="answer-bg-model-n-sum">{{ ques_name }}</div>
          <div class="answer-bg-model-n-sum">共{{ total }}题</div>
        </div>
      </div>
    </div>

    <div class="answer-content">
      <div class="answer-content-model" v-loading="loading">
        <!-- 题目 -->

        <div
          v-for="(item, index) in answer_list"
          :key="index"
          class="answer_conter"
        >
          <div class="title_name">
            <!-- 题目类型 -->
            <span>({{ item.type_name }})</span>
            <div class="answer_title">
              <!-- 题目标题 -->
              <span
                v-html="machiningHtml(item.content, index + 1)"
                class="answer_content"
              ></span>
            </div>
          </div>
          <!-- 选项 -->
          <div class="option">
            <!-- 选择题 -->
            <div v-if="item.type_id == 'radio' || item.type_id == 'checkbox'">
              <div
                class="option_flex"
                v-for="(it, idx) in item.options"
                :key="idx"
              >
                <!-- <span :class="{'iconfont': it.is_correct === false && it.is_user_selected === true,'iconfont  iconfont1': it.is_correct === true,letter: it.is_user_selected === false}"> -->
                <span
                  :class="
                    item.right_answer == it.option_num
                      ? 'iconfont'
                      : 'iconfont1'
                  "
                >
                  {{ it.option_num }}
                </span>
                <span class="option_text" v-html="it.title"></span>
              </div>
            </div>

            <!-- 判断题 -->
            <div v-if="item.type_id === 'boolean'" class="estimate">
              <div class="option_texts">
                <span>
                  <span
                    :style="rightColor(item)"
                    class="iconfont el-icon-check"
                  ></span>
                </span>
                正确
              </div>
              <div class="option_texts">
                <span>
                  <span
                    :style="badColor(item)"
                    class="iconfont el-icon-close"
                  ></span>
                </span>
                错误
              </div>
            </div>
          </div>
          <!-- 答案 -->
          <div class="answer1">
            <!-- 有答案的 -->
            <div
              v-if="
                item.type_id === 'radio' ||
                item.type_id === 'boolean' ||
                item.type_id === 'checkbox'
              "
              class="result"
            >
              <span class="ans_right">
                正确答案:
                <span class="ans_right-id"> {{ item.right_answer }}</span>
              </span>
              <span class="ans_right-1"
                >你的答案:<span class="ans_right-1-id">{{
                  item.user_answer
                }}</span></span
              >

              <div class="ans_right-2">
                错题次数：<span class="ans_right-2-sum">{{
                  item.err_num
                }}</span>
              </div>
            </div>
          </div>
          <!-- 解析 -->
          <div class="analysis_text">
            <span class="title">解析</span>
            <div class="title1" v-if="item.resolution == null">暂无解析</div>
            <span class="ql-editor" v-html="item.resolution"></span>
          </div>

          <div class="answer-content-model-subject-1-n-line"></div>
        </div>

        <div class="exit" @click="exit()">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getResolution } from "../../../http/api/wrongAnalysis";
export default {
  created() {
    //在data中定义的数据还没被绑定,所以不要在这里赋值
  },
  mounted() {
    //定义数据已经被绑定  可以在这里进行数据ajax绑定

    this.getResolution();
  },
  props: {
    //
  },
  destroyed() {},
  data() {
    return {
      answer_list: [],
      ans_id: "", //id
      total: "",
      title: "",
      //返回
      name: "",
      sum: "",
      id: "",
      loading: true,
      ques_name: "",
      //位置
      show: true,
    };
  },

  watch: {},
  methods: {
    handleScroll() {
      var intervaldata =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log("xxx", document.body.clientHeight);

      if (intervaldata > 0) {
        this.show = true;
      } else {
        this.show = false;
      }
    },

    exit() {
      var items = this.$router.history.current.query.items;
      this.$router
        .push(`chapterOptions?items=${items}`)
        .then(() => {})
        .catch((err) => err);
    },

    //加工html
    machiningHtml(item, index) {
      // console.log(index);

      var html = item.slice(0, 3) + `${index}.` + item.slice(3);
      // console.log(html);

      return html;
    },
    getResolution() {
      this.ques_name = this.$router.history.current.query.ques_name;
      this.name = this.$router.history.current.query.name;
      // this.title = this.$router.history.current.query.title;
      // this.name = this.$router.history.current.query.name;
      //  this.sum = this.$router.history.current.query.sum;
      this.ques_id = this.$router.history.current.query.ques_id;
      getResolution(this.ques_id).then((res) => {
        if (res.code == 200) {
          this.answer_list = res.answer_list;
          this.total = res.total;
          this.loading = false;
        }
        // console.log(res);
      });
    },
    rightColor(item) {
      if (item.right_answer === "正确") {
        return {
          color: "#fff",
          "background-color": "#4574f6",
          "border-color": "#4574f6",
        };
      }  else {
        return {
          color: "#4574f6",
          "background-color":"#fff",
          "border-color": "#4574f6",
        };
      }
    },
    badColor(item) {
      if (item.right_answer === '错误') {
        return {
          color: "#fff",
          "background-color": "#4574f6",
          "border-color": "#4574f6",
        };
      }else {
        return {
          color: "#4574f6",
          "background-color":"#fff",
          "border-color": "#4574f6",
        };
      }
    },
  },
};
</script>

<style scoped>
.answer {
  width: 100%;
}
.answer-bg {
  width: 100%;
  height: 164px;
  background: url("../../../assets/img/analysis.png");
  background-size: cover;
}

.answer-bg-model {
  width: 67%;
  margin: 0 auto;
  display: flex;
  padding-top: 5px;
  height: 155px;
  /* text-align: center; */
  align-items: center;
  justify-content: center;
}
.answer-bg-model-n {
  margin-top: -28px;
}
.answer-bg-model-n-name {
  color: white;

  text-align: center;
  font-size: 18px;
  margin-top: -15px;
}
.answer-bg-model-n-sum {
  color: white;
  text-align: center;
  font-size: 14px;
  margin-top: 8px;
}

.answer-content {
  width: 100%;
}
.answer-content-model {
  width: 65%;
  margin: auto;
  position: relative;
  top: -40px;
  overflow: hidden;

  border-radius: 5px;
  background: white;
  padding: 1%;

  padding-bottom: 100px;
}
.answer-content-model-subject {
}
.answer-content-model-statistics {
  margin: 23px 0;
  padding-left: 10px;
}
.answer-content-model-submit {
  margin: 23px 0;
  margin-top: 30px;
  padding-left: 10px;
}
.submit {
  background: #0080ff;
  color: white;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;

  width: 84px;
  height: 32px;
  line-height: 32px;
}
.answer-content-model-subject-1-n {
  padding: 20px 0;
}
.answer-content-model-subject-1-n-line {
  width: 99%;
  height: 1px;
  background: #e3ebf3;
  margin-top: 48px;
  margin-left: 10px;
  margin-bottom: 48px;
}
.subject-1-n-subject {
  color: #00264d;
  display: flex;
  font-size: 18px;
  font-weight: 600;

  line-height: 27px;
  align-items: flex-start;

  margin: 8px 0;
}
.subject-1-n-category {
  color: #00264d;
  font-size: 18px;
  margin-bottom: 10px;
  margin-left: 10px;
}
.subject-1-n-option {
  display: flex;
  align-items: center;

  cursor: pointer;
  padding-left: 10px;
}
.subject-1-n-option:hover {
  background: #f1f4f9;
}
.subject-1-n-option-id {
  width: 24px;
  height: 24px;
  background: #0080ff;
  border-radius: 50%;
  font-size: 13px;
  color: white;
  line-height: 24px;
  text-align: center;
  border: 1px solid #0080ff;
  margin-right: 16px;
}

.subject-1-n-option-id2 {
  width: 24px;
  height: 24px;
  background: white;
  border-radius: 50%;
  font-size: 13px;
  color: #0080ff;
  line-height: 24px;
  text-align: center;
  border: 1px solid #0080ff;
  margin-right: 16px;
}
.subject-1-n-option-name {
  font-size: 16px;
}
.answer-content-model-statistics-n {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.answer-content-model-statistics-n-ok {
  color: #0080ff;
}
.answer-content-model-statistics-n-no {
  color: #fc6257;
  margin: 0 18px;
}
.answer-content-model-statistics-n-total {
  color: #657489;
  margin-left: 21px;
}

/********************************************************** */

.option_texts {
  margin-top: 10px;
  margin-left: 10px;
  color: #00264d;
  font-size: 17px;
}
.letter {
  width: 24px;
  height: 24px;

  display: block;
  border-radius: 50%;
  line-height: 24px;
  text-align: center;
  border: 1px #4574f6 solid;
}

.radio_item {
  display: flex;
  cursor: pointer;
  height: 40px;
  margin: 10px 0;
  align-items: center;
  padding-left: 10px;
}
.radio_item:hover {
  transition: 0.5s;
  background: #f1f4f9;
}

.htmlP {
  display: block;
  font-size: 18px;
  color: #00264d;

  margin-left: 10px;
}
.subject-1-n-subject-html {
}
.title_name {
  padding-left: 10px;
  font-size: 18px;
  color: #00264d;
}
.quse_conter {
  padding: 20px 0;
}
.subject-1-n-subject-index {
  display: block;
  padding: 18px 0;
}
.subject-1-n-subject-index-content {
}
.style {
  font-size: 18px;
  color: #00264d;
  font-weight: 700;
}
.option_flex {
  display: flex;
  align-items: center;
}
.option_text {
  margin-left: 10px;
  color: #00264d;
  font-size: 17px;
}

.letter {
  color: #2377e3;
}
.iconfont {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #2377e3;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  border: 1px #2377e3 solid;
  color: white;
}
.iconfont1 {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  text-align: center;
  line-height: 24px;
  font-size: 16px;
  color: #2377e3;
  border: 1px #2377e3 solid;
}

.result {
  display: flex;
  margin: 15px 0;
}
.ans_right {
  color: #00264d;
  font-size: 18px;
}
.ans_right-1 {
  color: #00264d;
  font-size: 18px;
  margin-left: 40px;
}
.ans_right-id {
  margin-left: 10px;
}

.answer-content-model-subject-1-n-line {
  width: 99%;
  height: 1px;
  background: #e3ebf3;
  margin-top: 48px;
  margin-left: 10px;
}
.option {
  padding-left: 10px;
}
.answer1 {
  padding-left: 10px;
  margin: 20px 0;
}
.analysis_text {
  padding-left: 10px;
  margin: 20px 0;
  color: #00264d;
  font-size: 18px;
}
.answer_content {
  font-size: 18px;
  height: 27px;
  font-weight: 700;

  color: #00264d;
}
.ans_right-1-id {
  margin-left: 20px;
}
.ans_right-2 {
  margin-left: 74px;
  color: #00264d;
  font-size: 18px;
}
.option_flex {
  margin: 15px 0;
}
.exit {
  background: #0080ff;
  color: white;
  border-radius: 6px;
  text-align: center;
  cursor: pointer;

  width: 84px;
  height: 32px;
  line-height: 32px;
  font-size: 16px;
  margin-left: 10px;
}
.answer-bg-model-n-name1 {
  font-size: 22px;
  color: white;
  text-align: center;
}
.answer-bg-model-n-name2 {
  font-size: 16px;
  color: white;
  margin-top: 10px;
}
.title1 {
  margin: 10px 0;
}
.ans_right-2-sum {
  color: #fc6257;
  font-weight: 600;
}
/* 移动端 */
@media screen and (max-width: 1024px) {
  .answer-bg-model{
    width: calc(100% - 40px);
    padding: 0 20px;
  }
  .answer-content,
  .answer-content-model{
    width: calc(100% - 40px);
    padding: 0 20px;
    padding-bottom: 100px;
  }
  .answer_conter{
    margin-top: 20px;
  }
  .answer-content-model-subject-1-n-line {
    width: 100%;
    height: 1px;
    background: #e3ebf3;
    margin: 48px 0;
  }
  .result{
    display: block;
  }
  .ans_right-2{
    margin: 0;
    margin-top: 8px;
  }
}
</style>

